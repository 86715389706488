#portrait {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

#portrait-container {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  align-items: center;
  display: flex;
}

#animation-page {
  background-color: var(--background-color);
  height: calc(100vh - 80px);
  padding: 20px;
  overflow: hidden;
}

#home-page {
  background-color: var(--background-color);
  min-height: calc(100vh - 80px);
  padding: "20px";
  color: var(--primary-color);
  font-family: "OCR A Std";
  display: flex;
}

.terminal-text {
  font-family: "OCR A Std";
  color: var(--primary-color);
}
