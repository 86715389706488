.navbar {
  background: black;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-family: "OCR A Std", sans-serif;
  color: var(--primary-color);
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 1500px;
  width: 100%;
  padding: 0 20px;
  font: inherit;
}

.fa-house {
  margin-left: 0.5rem;
  margin-right: 20px;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 40px;
  list-style: none;
  text-align: center;
  justify-content: end;
  align-items: center;
  margin-right: 2rem;
  font: inherit;
  height: 100%;
  color: var(--primary-color);
}

.nav-item {
  height: 100%;
  display: flex;
  align-items: center;
  font: inherit;
}

.navbar-logo {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font: inherit;
  color: var(--primary-color);
  cursor: pointer;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    height: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    overflow: auto;
    z-index: 100;
  }

  .nav-menu.active {
    background: black;
    left: 0;
    opacity: 1;
    grid-gap: 0px;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-bars {
    color: var(--primary-color);
  }

  .fa-times {
    color: var(--primary-color);
    font-size: 2rem;
  }
}
