.main-box {
  position: absolute;
  width: calc(25vw + 100px);
  height: calc(50vh + 150px);
  min-width: 475px;
  max-width: 600px;
  min-height: 600px;
  max-height: 750px;
  font-family: "OCR A Std", sans-serif;
  overflow: hidden;
}

.visible-box {
  border: 2px solid var(--primary-color);
  background-color: var(--background-color);
}

.hidden-box {
  border: none;
  background-color: transparent;
}

.top-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
  border-bottom: 2px solid var(--primary-color);
  z-index: 3;
}

.visible-top-box {
  background-color: var(--background-color);
  border-bottom: 2px solid var(--primary-color);
}

.hidden-top-box {
  background-color: transparent;
  border: none;
}

.close-button {
  background: none;
  border: none;
  color: var(--primary-color);
  font-size: 20px;
  cursor: pointer;
}

.hidden-top-box .close-button {
  color: transparent;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  z-index: 2;
  transform: translateY(-100%); /* Hidden position before animation */
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(100%);
  }
}

.slide-transition {
  animation: slideDown 1s forwards ease-in-out;
}

.slide-reset {
  transform: translateY(-100%);
}

.title-text {
  color: var(--primary-color);
  font-size: 24px;
  margin-bottom: 10px;
  z-index: 3;
}

.role-text {
  color: var(--primary-color);
  z-index: 3;
}

.body-text {
  color: var(--primary-color);
  font-size: 16px;
  margin-bottom: 10px;
  z-index: 3;
  font-size: 16px;
  line-height: 1.5;
}

.fa-times {
  color: inherit;
  font-size: 20px;
  cursor: pointer;
}

.fa-github {
  color: var(--primary-color);
}
