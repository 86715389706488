.skill-icon svg {
  color: var(--primary-color);
  width: 30px;
  height: 30px;
}

.skill-icon {
  padding: 5px;
  text-align: center;
}
