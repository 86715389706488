.entry-box-mobile {
  width: 550px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--primary-color);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: none;
  color: var(--primary-color);
}

.role-text {
  color: var(--primary-color);
  z-index: 3;
}

.fa-github {
  color: var(--primary-color);
}

@media (max-width: 550px) {
  .entry-box-mobile {
    width: 100%;
  }
}
