:root {
  --primary-color: #4af626;
  --background-color: black;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "OCR A Std", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: var(--background-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-color);
}
